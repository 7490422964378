import React, {Component} from 'react';
import {CSSTransition} from "react-transition-group";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {assignEvent} from "../../../core/helpers";
import {icons} from "../../../assets/images";

const duration = 300;
const store = observable({
    isModalOpen: false
});
const modal = {
    open() {
        document.body.classList.add('modal-open')
        store.isModalOpen = true;
    },
    close() {
        document.body.classList.remove('modal-open')
        store.isModalOpen = false;
    }
}

@observer
class ModalHelp extends Component {
    showModalTimer = 0;

    componentDidMount() {
        // console.log('setModal');
        this.setModalTimer();
        this.removeListeners = assignEvent(
            window,
            'mousedown mousemove mousewheel touchstart touchmove',
            () => {
                this.setModalTimer();
            });
    }

    componentWillUnmount() {
        if (typeof this.removeListeners === 'function')
            this.removeListeners();
        clearTimeout(this.showModalTimer);
    }

    setModalTimer = () => {
        clearTimeout(this.showModalTimer);
        this.showModalTimer = setTimeout(() => {
            modal.open();
        }, 1000 * 60 * 3)
    };

    render() {
        return (
            <CSSTransition
                timeout={duration} classNames="fade"
                unmountOnExit
                in={store.isModalOpen}
            >
                <div className={'modal-box modal-help transition-group'}>
                    <Overlay/>
                    <Modal/>
                </div>
            </CSSTransition>
        );
    }
}

function Modal() {
    return <div className={'modal-wrapper'}>
        <div className="close-btn" onClick={modal.close}/>
        <img src={icons.modal} alt=""/>
        <header> صرفا جهت اطلاع</header>
        <div className="content">
            شما 3 دقیقه توی این صفحه بدون فعالیت بودید
        </div>
        <a href="tell:06133335597" className={'btn blue'}>تماس با پشتیبانی</a>
    </div>;
}

function Overlay() {
    return <div className={'modal-overlay overlay'} onClick={modal.close}/>;
}

export default ModalHelp;
