import React, {Component} from 'react';
import ButtonBack from "../../../components/ButtonBack";
import {icons} from "../../../assets/images";

export default class Ethics extends Component {
    render() {
        return (
            <div className={'screen about-us ethics'}>
                <ButtonBack/>
                <header className="main">
                    <header className="title">
                        <div className="logo"><img src={icons.aboutLogo} alt=""/></div>
                        <span>منشور اخلاقی</span>
                    </header>
                </header>
                <div className="wrapper">
                    {/*{Array(6).fill('').map((_, i) => <div className="content" key={i}>*/}
                    {/*    {content}*/}
                    {/*</div>)}*/}
                    <p className="center">بسمه تعالی</p>
                    <p className="center">منشور اخلاقی و حرفه ای شرکت پخش توزیع گستران</p>

                    <p>ما پرسنل شرکت توزیع گستران درهمه حال خداوند متعال را شاهد و ناظر بر کار، رفتاروگفتار خود می دانیم
                        و براین اساس همواره می کوشیم تا به درستی کار کنیم.</p>
                    <p>ما در همه حال خود را متعهد به رعایت اخلاق شایسته ، ادب ، واحترام در قبال تمامی همکاران ،مشتریان و
                        ارباب رجوع سازمان می دانیم .</p>
                    <p>ما همواره در تلاشیم تا از طریق فراگیری وآموزش ، اطلاعات خود را به روز و دانش خود را ارتقا دهیم
                        .</p>
                    <p>ما پیشنهادها و انتقادات دیگران را فرصتی برای اصلاح و بهبود خود می دانیم .</p>
                    <p>ما همواره روحیه قدردانی و سپاسگذاری از دیگران را در وجود خود تقویت می کنیم .</p>
                    <p>ما منافع مشتریان را منافع خود می دانیم و براین باوریم که مشتریان ، شرکای اقتصادی ما هستند .</p>
                    <p>ما براین باوریم که رسالتمان ، ارائه بهترین محصولات ، درکوتاهترین زمان و با بالاترین کیفیت به دست
                        مشتریان است ، این یک هدف است که همه ما درپی تحقق آن به بهترین نحوممکن هستیم .</p>
                    <p>ما براین باوریم که اظهار رضایت مشتریان و حسن اعتماد ایشان ، بزرگترین پاداش کار مان است .</p>
                    <p>ما خود را متعهد به حفظ و ارتقا سرمایه های معنوی و مادی سازمان می دانیم .</p>
                    <p>ما در امورسازمان خود مشارکت فعال و سازنده میکنیم و براین باوریم که رشد سازمان موجب رشد ما و ارتقا
                        ما زمینه ساز ارتقا سازمان است .</p>
                    <p>ما با اعتقاد به اصل تفاوت آرا و دیدگاه ها به تصمیمات سازمانی که حاصل خرد جمعی است ، احترام گذاشته
                        و خود را تابع قوانین و مقررات سازمان می دانیم .</p>
                    <p>آینده و سرنوشت شرکت تأثیر مستقیم و عمیق بربهبود و ارتقا کیفیت زندگی ما دارد ، پس خود را به
                        مأموریت و اهداف شرکت متعهد و وفادار می دانیم و در این راستا وظایف محوله را با جدیت ، درستی و
                        بهترین کیفیت انجام می دهیم .</p>


                </div>
            </div>
        );
    }
}

// let content = `لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
// چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون
// بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است`;
