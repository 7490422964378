import React from "react";

import {Route, Switch} from "react-router-dom";
import Splash from "./Screens/Splash";
import Login from "./Screens/Login/Login";
import Verify from "./Screens/Login/Verify";
import DashboardContainer from "./Layouts/DashboardContainer";
import Dashboard from "./Screens/Dashboard/Dashboard";
import NoMatch from "./Screens/NoMatch";
import Factors from "./Screens/Dashboard/Factors";
import Checks from "./Screens/Dashboard/Checks";
import AboutUs from "./Screens/Dashboard/AboutUs";
import Ethics from "./Screens/Dashboard/Ethics";
import ContactUs from "./Screens/Dashboard/ContactUs";
import Catalog from "./Screens/Dashboard/Catalog";
import Blog from "./Screens/Dashboard/Blog";
import Gifts from "./Screens/Dashboard/Gifts";
import Suggestions from "./Screens/Dashboard/Suggestions";
import Certificates from "./Screens/Dashboard/Certificates";
import MapComp from "./Screens/Dashboard/Map";
import Single from "./Screens/Dashboard/Single";
import Poll from "./Screens/Dashboard/Poll";

export default ({location}) => {
    return (
        <Switch location={location}>
            <Route path="/" exact component={Splash}/>
            <Route path="/login" component={Login}/>
            <Route path="/verify" component={Verify}/>

            <DashboardContainer>
                <Switch location={location}>
                    <Route path="/dashboard" component={Dashboard}/>
                    <Route path="/factors" component={Factors}/>
                    <Route path="/checks" component={Checks}/>
                    <Route path="/gift-card" component={Gifts}/>
                    <Route path="/about-us" component={AboutUs}/>
                    <Route path="/contact-us" component={ContactUs}/>
                    <Route path="/ethics" component={Ethics}/>
                    <Route path="/catalog" component={Catalog}/>
                    <Route path="/suggestions" component={Suggestions}/>
                    <Route path="/certificates" component={Certificates}/>
                    <Route path="/blog" component={Blog}/>
                    <Route path="/map" component={MapComp}/>
                    <Route path="/single/:id" component={Single}/>
                    <Route path="/poll/:id" component={Poll}/>
                    <Route component={NoMatch}/>
                </Switch>
            </DashboardContainer>
        </Switch>
    );
}
