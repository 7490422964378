import React, {Component} from 'react';

export default class Loading extends Component {
    render() {
        return (
            <div className={'loading-spinner'}>
                <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2"/>
                </svg>
                <div className="label">در حال دریافت اطلاعات</div>
            </div>
        );
    }
}
