import appState from "../store/appState";

export const GoogleMapApiKey = 'AIzaSyBpk9PwKrat_FIRCRt2IRYBkrtcvH-xcZw';

export function priceFormat(amount, decimalCount = 0, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

export function getMeta(key) {
    if (!appState.logged_in) return;
    return appState.user.additionals && appState.user.additionals[key];
    // let found = appState.user.additional && appState.user.additional[0].find(i => i.meta_key === key);
    // return found && found.meta_value;
}

export function getScrollTop() {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
    return supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
}

export function assignEvent(elm, events, handler) {
    if (typeof events === 'string') events = [...events.split(' ')];

    events.forEach((e) => elm.addEventListener(e, handler));
    return () => {
        events.forEach((e) => elm.removeEventListener(e, handler));
    }
}
