import React, {Component} from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {icons} from "../../../assets/images";
import ButtonBack from "../../../components/ButtonBack";
import classNames from "classnames";
import api from "../../../api";
import ResultMessage from "../../../components/ResultMessage";

const duration = 500;

export default class ContactUs extends Component {
    // state = {
    //     tab: '1',
    // };
    constructor(props) {
        super(props);
        this.state = {tab: '1'}
    }

    changeTab = (tab) => {
        this.setState({
            tab
        });
    };

    componentDidMount() {
        document.body.classList.add('dark-statusbar')
    }

    componentWillUnmount() {
        document.body.classList.remove('dark-statusbar')
    }

    render() {
        let current = this.state.tab;
        return (
            <div className={'screen contact-us'}>
                <ButtonBack/>
                <div className="map"/>
                <div className="tabs">
                    <header>
                        <div className={`item ${current === "1" ? 'active' : ''}`}
                             onClick={() => this.changeTab('1')}>اطلاعات تماس
                        </div>
                        <div className={`item ${current === "2" ? 'active' : ''}`}
                             onClick={() => this.changeTab('2')}>فرم تماس با ما
                        </div>
                        <div className="spinner"/>
                    </header>
                    <div className="content">
                        <TransitionGroup className="transition-group">
                            {this.state.tab === '1' &&
                            <CSSTransition timeout={duration} classNames="fade" key="tab-1">
                                <ContactDetail/>
                            </CSSTransition>}
                            {this.state.tab === '2' &&
                            <CSSTransition timeout={duration} classNames="fade" key="tab-2">
                                <ContactForm/>
                            </CSSTransition>}
                        </TransitionGroup>
                    </div>
                </div>
            </div>
        );
    }
}

function ContactDetail() {
    return (
        <div className={'tab-content details'}>
            <ul>
                <li><img src={icons.phoneRed} alt=""/>تلفن تماس : <span>06132907210</span></li>
                <li><img src={icons.phoneRed} alt=""/>فاکس:  <span>02189781577</span></li>
                <li><img src={icons.mapRed} alt=""/>پست الکترونیکی: <span>tozi.gostaran@gmail.com</span></li>
                <li><img src={icons.mapRed} alt=""/>آدرس:  <span>اهواز ابتدای جاده ماهشهر شهرک صنعتی شماره 3 خیابان تلاش 1 پلاک 243</span>
                </li>
                <li><img src={icons.instagram} alt=""/>
                    <a href="https://instagram.com/tozigostaran">tozigostaran</a>
                </li>
            </ul>
        </div>
    );
}

class ContactForm extends Component {
    state = {
        loading: false,
        value: '',
        message: '',
    }
    submitForm = (e) => {
        e.preventDefault();
        if (!this.state.value) return;
        this.setState({
            loading: true,
            message: '',
        });
        api().post('feedback', {
            title: 'تماس با ما',
            message: this.state.value,
        }).then(({data}) => {
            this.setState({
                message: data.message,
                value: '',
            })
        }).finally(() => {
            this.setState({
                loading: false
            });
        })
    }

    render() {
        return (
            <div className={'tab-content'}>
                <form action="" onSubmit={(e) => this.submitForm(e)}>
                    <header className="label"><img src={icons.file} alt=""/> توضیحات</header>
                    <textarea name="" id="" className={'form-control'}
                              onChange={(e) => this.setState({value: e.target.value})}
                              value={this.state.value} required/>
                    <button type='submit'
                            className={classNames(['btn blue rounded', this.state.loading ? 'loading' : ''])}>ارسال پیام
                    </button>
                    <ResultMessage message={this.state.message}/>

                </form>
            </div>
        );
    }
}
