import api from "../api";
import {icons} from "../assets/images";

// let notify_id = 0;
export default function CheckNotification() {
    window.navigator.serviceWorker.getRegistration().then((reg) => {
        // console.info('CheckNotification')
        if (Notification.permission !== 'granted') return;

        api().get('/poll', {
            headers: {
                Poll: '1'
            }
        }).then(({data}) => {
            if (!data.polls || (Array.isArray(data.polls) && data.polls.length === 0))
                return;

            data.polls.forEach((poll) => {
                const title = 'توزیع گستران';
                const options = {
                    body: poll.title,
                    tag: 'poll-' + poll.id,
                    icon: icons.aboutLogo,
                }

                reg.showNotification(title, options);

                // notify_id++;
                // let id = notify_id;
                // let notify = new Notification("توزیع گستران #" + id, {
                //     body: poll.title,
                //     tag: notify_id,
                //     icon: icons.aboutLogo,
                //     lang: "",
                //     dir: "auto",
                // });
                //
                //
                // notify.onclick = function () {
                //     let w = window.open(window.location.origin + '/poll/' + poll.id);
                //     w.apiToken = window.localStorage.getItem('token');
                // };
                // notify.onshow = function () {
                //     console.log(id, "showed");
                // };
                // notify.onerror = function () {
                //     console.log(id, "errored");
                // };
                // notify.onclose = function () {
                //     console.log(id, "closed");
                // };
            });

        })


    }).catch(e => {
        console.log(e);
    });
}
