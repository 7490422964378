import React, {Component} from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
import {observer} from "mobx-react/dist/mobx-react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import classnames from "classnames";
import {sidebar as icons, icons as _icons} from "../../../assets/images";
import appState from "../../../store/appState";
import {withRouter} from "react-router-dom";
import {modal} from './ModalUploader';

const duration = 300;

@withRouter
@observer
class Sidebar extends Component {
    state = {
        isOpenUl: false,
    }

    componentDidMount() {
        let links = ['/about-us', '/contact-us', '/ethics', '/certificates', '/map', '/suggestions'];
        if (!this.state.isOpenUl && links.includes(this.props.location.pathname)) {
            this.setState({
                isOpenUl: true
            })
        }
    }

    handleClick(link) {
        appState.isSidebar = false;
        if (this.props.location.pathname !== link) {
            setTimeout(() => {
                this.props.history.push(link)
            }, 300)
        }
    }

    render() {
        const {store: appState} = this.props;
        const {MenuItem} = this;

        let isOpenUl = this.state.isOpenUl;

        return (
            <>
                <Overlay visible={this.props.store.isSidebar} store={appState}/>
                <div className={classnames([this.props.store.isSidebar ? 'active' : '', 'sidebar-wrapper'])}>
                    <HeaderSidebar store={appState}/>
                    <nav className="menu">
                        <ul>
                            <MenuItem icon={icons.home} text={'صفحه‌اصلی'} link={'/dashboard'}/>
                            <MenuItem icon={icons.info}
                                      text={'بخش عمومی'}
                                      active={isOpenUl}
                                      className={'has-child'}
                                      onClick={() => {
                                          this.setState((prev) => ({
                                              isOpenUl: !prev.isOpenUl,
                                          }));
                                      }}>
                                <ul>
                                    <MenuItem icon={icons.save} text={'درباره‌ما'} link={'/about-us'}/>
                                    <MenuItem icon={icons.phone} text={'تماس‌با‌ما'} link={'/contact-us'}/>
                                    <MenuItem icon={icons.list} text={'منشور‌اخلاقی'} link={'/ethics'}/>
                                    <MenuItem icon={icons.info} text={'مجوزها'} link={'/certificates'}/>
                                    <MenuItem icon={icons.info} text={'موقعیت جغرافیایی'} link={'/map'}/>
                                    <MenuItem icon={icons.email} text={'فرم انتقادادت و پیشنهادات'}
                                              link={'/suggestions'}/>
                                </ul>
                            </MenuItem>

                            <MenuItem icon={icons.add} text={'پیش‌فاکتور'} link={'/factors'}/>
                            <MenuItem icon={icons.building} text={'چک‌ها'} link={'/checks'}/>
                            <MenuItem icon={icons.list} text={'کارت‌هدیه'} link={'/gift-card'}/>
                            <MenuItem icon={icons.info} text={'آموزش'} link={'/blog'}/>
                            <MenuItem icon={icons.info} text={'کاتالوگ'} link={'/catalog'}/>
                            <MenuItem icon={icons.add} text={'نصب اپلیکیشن'}
                                      disabled={appState.isDisableAddToHome && appState.installPromptEvent}
                                      onClick={() => {
                                          if (appState.installPromptEvent && typeof appState.installPromptEvent.prompt =='function'){
                                              appState.installPromptEvent.prompt();
                                          } else {
                                              alert('برای نصب اپلیکیشن کافیست در تنظیمات مرورگر خود گزینه‌ی Add To Home را انتخاب نمایید.');
                                          }

                                      }}/>
                            
                            <MenuItem icon={icons.add} text={'بروزرسانی'}
                                      onClick={() => {
                                          window.caches.keys().then((names) => {
                                              for (let name of names)
                                                  window.caches.delete(name);
                                          });
                                          alert('بروزرسانی با موفقیت انجام شد.');
                                          window.location.reload()
                                      }}/>
                            <MenuItem icon={icons.lock} text={'خروج'} link={'/logout'}/>
                        </ul>
                    </nav>
                </div>
            </>
        );
    }

    MenuItem = ({icon, text, link, onClick, children, active = false, className = '', disabled = false}) => {

        return (
            <li className={className}>
                <button disabled={disabled} onClick={(e) => onClick ? onClick(e) : this.handleClick(link)}
                        className={active || this.props.location.pathname === link ? 'active' : ''}>
                    <div className="image"><img src={icon} alt=""/></div>
                    <span className="text">{text}</span>
                </button>
                {children}
            </li>
        );
    };
}

function Overlay({visible, store}) {
    return (<TransitionGroup className="transition-group">
        {visible && <CSSTransition
            timeout={duration} classNames="fade"
            key="sidebar-overlay"
        >
            <div className={'sidebar-overlay overlay'} onClick={() => store.isSidebar = false}/>
        </CSSTransition>}
    </TransitionGroup>)
}

const HeaderSidebar = observer(({store}) => {
    return <HeaderCurve className={'center'} listeners={false}>
        <div className="store-image" onClick={modal.open}>
            <img src={store.user.img && store.user.img !== 'none' ? store.user.img : _icons.store} alt=""/>
            <div className={'uploader'}>
                <img src={_icons.camera} alt=""/>
            </div>
        </div>
        {store.user.name}
    </HeaderCurve>;
})

export default Sidebar;
