import {observable} from "mobx";
import {icons} from "../assets/images";
import CheckNotification from "../core/CheckNotification";

const appState = observable({
    _isSidebar: false,
    logged_in: false,
    requestedNotification: false,
    isDisableAddToHome: true,
    installPromptEvent: null,
    token: '',
    user: {
        phone: '',
        // uuid: '0',
        // phone: '09169942377',
        uuid: '11000000',
        name: '---',
        img: icons.store,
        additional: []
    },
    dashboard: {
        allGiftAmount: "---",
        allLastGiftAmount: "---",
        lastActiveGift: [],
        lastFactor: null
    },
    store: {
        name: 'عنوان فروشگاه',
    },
    set isSidebar(v) {
        this._isSidebar = v;
        document.body.classList.toggle('overflow-hidden', v)
    },
    get isSidebar() {
        return this._isSidebar;
    },

    login(token) {
        this.token = token;
        localStorage.setItem('token', token);
    },
    logout() {
        this.token = null;
        this.logged_in = false;
        localStorage.setItem('token', null);
    },
    notify() {
        if (!('Notification' in window) || this.requestedNotification) return;

        if (Notification.permission === 'granted') {
            this.requestedNotification = true;
            setInterval(() => {
                CheckNotification();
            }, 10 * 1000);
        } else {
            Notification.requestPermission().then(() => {
                this.requestedNotification = true;
                CheckNotification();
                setInterval(() => {
                    CheckNotification();
                }, 10 * 1000);
            }).catch(() => {
                setTimeout(() => {
                    this.notify();
                }, 10000)
            });
        }
    },
});

export default appState;
