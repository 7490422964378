import React from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
import ListView from "../../../components/ListView";
import GiftCard from "../../../components/GiftCard";

export default () => {
    return (<div className={'screen checks'}>
        <HeaderCurve title='کارت هدیه' back={true}/>
        <ListView uri={'/gift'} Template={(post) => <GiftCard key={post.id} gift={post}/>}/>
    </div>);
}