import React, {Component} from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
import locked from '../../../assets/images/locked.png';
import Keyboard from "./Keyboard";
import {Transition} from "react-transition-group";
import {inject} from "mobx-react";
import {apiAuth} from "../../../api";
import {Redirect} from "react-router-dom";

const duration = 300;
const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    transform: `scale(.5)`
};
const transitionStyles = {
    entering: {opacity: 1, transform: `scale(1)`},
    entered: {opacity: 1, transform: `scale(1)`},
    exiting: {opacity: 0, transform: `scale(.5)`},
    exited: {opacity: 0, transform: `scale(.5)`},
};

@inject('store')
class Verify extends Component {
    state = {
        loading: false,
        value: '',
        error_msg: '',
    };
    login_attemps = 0;

    addValue = (v) => {
        this.setState({error_msg: ''});

        if (v === 'done') {
            if (this.state.value.length >= 4) {
                this.submit();
            } else {
                this.setState({error_msg: 'کد وارد شده صحیح نیست!'});
            }
            return;
        } else if (v === 'backspace') {
            if (this.state.value.length > 0)
                this.setState(({value}) => ({value: value.slice(0, -1)}));
            return;
        }

        if (this.state.value.length >= 4)
            return;

        this.setState(({value}) => ({
            value: `${value}${v}`
        }));
    };

    submit = async () => {
        let {uuid, phone} = this.props.store.user;
        this.setState({
            error_msg: '',
            loading: true,
        });
        let form = {uuid, phone, verify_code: this.state.value};
        try {
            let {data} = await apiAuth().put('login', form);
            if (data.success) {
                this.props.store.login(data.data.token);
                this.props.history.replace('/dashboard');
            } else {
                if (this.login_attemps++ > 2) {
                    this.props.store.user.phone = '';
                }
                this.setState({
                    error_msg: data.message
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({
                loading: false,
            })
        }

    };

    render() {
        if (!this.props.store.user.phone || !this.props.store.user.uuid) {
            return <Redirect to={'/login'}/>
        }
        return (
            <div className={'screen verify scaffold'}>
                <HeaderCurve title={'کد تایید'} listeners={false}/>
                <div className="wrapper">
                    <div className={'expanded d-flex flex-center flex-column'}>
                        <img src={locked} alt=""/>
                        <p className={'hint'}>کد پیامک شده را لطفا وارد نمایید</p>
                        <Digits value={this.state.value}/>
                        <Transition in={!!this.state.error_msg} timeout={duration}>
                            {state => (
                                <div className='center' style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state]
                                }}>
                                    <p className={'error color-red'}>{this.state.error_msg}</p>
                                </div>
                            )}
                        </Transition>
                    </div>
                    <Keyboard value={this.state.value} addValue={this.addValue} loading={this.state.loading}/>
                </div>
            </div>
        );
    }
}

function Digits({value}) {
    return (
        <div className="digits d-flex justify-content-center">
            {[...Array(4)].map((v, i) => <div
                key={i}
                className={value.length >= 4 - i ? 'en' : ''}
            >{value.length >= 4 - i ? value[3 - i] : ''}</div>)}
        </div>
    );
}

export default Verify;
