import React from 'react';
import styled from "styled-components";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {icons} from "../assets/images";

export default ({color = [0, 0, 0], children, title, className = '', link}) => {
    const Color = styled.div`
        background: rgb(${color.join(', ')});
        box-shadow: 0 3px 10px rgba(${color.join(', ')},.22);
    `;

    return (
        <div className={classnames(['page-card', className])}>
            <div className="inner">
                {(link || title) && <header className={'d-flex flex-space-between'}>
                    {title && <div className="title">{title}</div>}
                    {link && <Link to={link} className="link">مشاهده همه <img src={icons.arrowLeft} alt=""/></Link>}
                </header>}
                <div className="body">
                    <Color className={'color'}/>
                    {children}
                </div>
            </div>
        </div>
    )
}
