import HeaderCurve from "../../../components/HeaderCurve";
import React, {Component} from "react";

import ButtonBack from "../../../components/ButtonBack";
import api from "../../../api";
import Loading from "../../../components/Loading";
import styled from "styled-components";

export default class Single extends Component {
    state = {
        loading: true,
        post: {}
    }

    constructor(props) {
        super(props);
        const id = props.match.params.id;

        api().get('/post/single/' + id).then(({data: post}) => {
            this.setState({
                post,
                loading: false
            })
        });
    }

    render() {
        const {post} = this.state;
        if (this.state.loading) {
            return <>
                <HeaderCurve title='در حال بارگذاری صفحه'/>
                <div className="wrapper list-view"><Loading/></div>
            </>
        }

        const Header = ({className, children}) => (
            <header className={`main ${className}`}>
                <img src={post.thumbnail} alt=""/>
                <header className="title">
                    <span>{post.title}</span>
                </header>
            </header>
        );

        const StyledHeader = styled(Header)`
        &,header.title::before{
          background: url(${post.thumbnail}) center bottom/ 100% auto;
        }   
      `
        return (
            <div className={'screen single'}>
                <ButtonBack/>
                <StyledHeader/>

                <div className="wrapper">
                    <article className={'post'}>
                        <div className="post-body">
                            <div className="content">
                                <pre style={{"white-space": "pre-line"}}>{post.description}</pre>
                            </div>
                        </div>
                    </article>
                    {post.additional && <Additional data={post.additional}/>}
                </div>
            </div>
        )
    }
}

function Additional({data: {image, video, voice}}) {


    return (
        <div className={'additional'}>
            {image && image.length > 0 && <article>
                <h2>تصاویر</h2>
                <div className="images">
                    {image.map((image, key) => <div key={key}><img src={image} alt=""/></div>)}
                </div>
            </article>}

            {video && video.length > 0 && <article>
                <h2>ویدیو ها</h2>
                <div className="videos">
                    {video.map((video, key) => <div key={key} dangerouslySetInnerHTML={{__html: video}}/>)}
                </div>
            </article>}

            {voice && voice.length > 0 && <article>
                <h2>فایل های صوتی</h2>
                <div className="voices">
                    {voice.map((url, key) => <audio src={url} controls key={key}/>)}
                </div>
            </article>}
        </div>
    );
}
