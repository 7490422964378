import React, {useEffect, useState} from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
import api from "../../../api";
import Loading from "../../../components/Loading";
import {icons} from "../../../assets/images";
import ResultMessage from "../../../components/ResultMessage";

export default (props) => {
    const [poll, setPoll] = useState(-1);
    const [result, setResult] = useState('');
    const [message, setMessage] = useState('');
    const [answers, setAnswers] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();
        const hasMessage = Object.entries(answers).some(([id, value]) => value > 0 && value < 5);
        if (hasMessage && !showMessage) {
            setShowMessage(true);
            return;
        }
        setLoading(true);

        api().put('/poll', {
            poll_id: poll.id,
            message: message || undefined,
            answers,
        }).then(({data}) => {
            let {message} = data;
            if (message)
                setResult(message);
            setMessage('');
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if (disabled) {
            if ((showMessage && `${message}`.trim() !== '') || (!showMessage && Object.keys(answers).length > 0)) {
                setDisabled(false);
            }
        } else if (showMessage && `${message}`.trim() === '') {
            setDisabled(true);
        }
    }, [answers, message, showMessage, disabled]);

    useEffect(() => {
        api()
            .get('/poll/' + props.match.params.id, {
                headers: {
                    Poll: 0
                }
            })
            .then(({data}) => {
                setPoll(data);
            });
    }, [props.match.params.id]);

    if (!poll || poll.length === 0) {
        return <>
            <HeaderCurve title={'نظرسنجی'}/>
            <div className="wrapper list-view">
                <div className="hint-box">
                    اطلاعاتی یافت نشد.
                </div>
            </div>
        </>;
    } else if (poll === -1) {
        return <>
            <HeaderCurve title={'نظرسنجی'}/>
            <div className="wrapper list-view">
                <Loading/>
            </div>
        </>;
    }

    return (
        <div className={'screen poll'}>
            <HeaderCurve title={'نظرسنجی'} back={true}>
                <div className="center mt-2">{poll.title}</div>
            </HeaderCurve>
            <div className="wrapper questions">
                <form action="" onSubmit={handleSubmit}>
                    {!showMessage && Object.entries(poll.questions).map((q) => (
                        <Question key={q[0]}
                                  question={q}
                                  answers={{...answers}}
                                  setAnswers={setAnswers}/>
                    ))}

                    {showMessage && <div className="message-box">
                        <header className="label"><img src={icons.file} alt=""/> <label
                            htmlFor="message">توضیحات</label>
                        </header>
                        <textarea value={message}
                                  className={'form-control'}
                                  onChange={(e) => setMessage(e.target.value)}
                                  required
                                  id={'message'} placeholder={'نظر خودرا بنویسید.'}/>
                    </div>}
                    <div className="center">
                        <button type={'submit'}
                                className={'btn blue rounded mt-2 ' + (loading ? 'loading' : '')}
                                disabled={disabled}>ارسال پیام
                        </button>
                        {result && <ResultMessage message={result}/>}

                    </div>
                </form>
            </div>
        </div>
    );
};

function Question({question: [id, value], answers, setAnswers}) {
    const [star, setStar] = useState(0);

    const handleClick = (i) => {
        setStar(i);
        answers[id] = i;
        setAnswers(answers);
    }
    return (
        <div className={'question'}>
            <div className="label">{value}</div>
            <div className="stars">
                {[...Array(5)].map((_, i) => <button key={i} type={'button'} onClick={() => handleClick(5 - i)}>
                    <img src={star < 5 - i ? icons.star_stroke : icons.star_fill} alt=""/>
                </button>)}
            </div>
        </div>
    );
}
