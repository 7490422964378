import React, {Component} from 'react';

export default class NoMatch extends Component {
    render() {
        return (
            <div>
                404, Not Found
            </div>
        );
    }
}