import React, {Component} from 'react';
import ButtonBack from "./ButtonBack";
import {getScrollTop} from "../core/helpers";

export default class HeaderCurve extends Component {
    componentDidMount() {
        if (this.props.listeners !== false)
            window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
        if (this.props.listeners !== false)
            window.removeEventListener('scroll', this.onScroll)
    }

    onScroll = (e) => {
        let force = getScrollTop() > 20;
        this.refs.fixedTitle.classList.toggle('visible', force);
    }

    render() {
        const {back, className = ''} = this.props;
        return (
            <>
                <header className={`curve ${className}`}>
                    <div className="title">{this.props.title}</div>
                    {this.props.children}
                </header>
                {back && <ButtonBack/>}
                <div className="fixed-title" ref={'fixedTitle'}>{this.props.title}</div>
            </>
        );
    }
}

