import React, {useEffect} from 'react';
import Container from "./Container";
import './assets/scss/app.scss';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "mobx-react";
import appState from "./store/appState";

export default () => {
    useEffect(() => {
        function listener(event) {
            event.preventDefault();
            appState.installPromptEvent = event;
            appState.isDisableAddToHome = false;
            event.userChoice.then((outcome) => {
                appState.isDisableAddToHome = true;
            });
        }

        window.addEventListener('beforeinstallprompt', listener);
        return () => {
            window.removeEventListener('beforeinstallprompt', listener);
        };
    }, []);

    return (
        <Router>
            <Provider store={appState}>
                <Container/>
            </Provider>
            {/*<AddToHome/>*/}
        </Router>
    );

}
//
// let installPromptEvent;
//
// function AddToHome() {
//     let [visible, setVisible] = React.useState(false);
//     React.useEffect(() => {
//         function listener(event) {
//             event.preventDefault();
//             installPromptEvent = event;
//
//             event.userChoice.then(function (outcome) {
//                 console.log(outcome); // either "accepted" or "dismissed"
//                 setVisible(false);
//             });
//             // if (!window.matchMedia('(display-mode: standalone)').matches) {}
//             setVisible(true);
//         };
//         window.addEventListener('beforeinstallprompt', listener);
//         return () => {
//             window.removeEventListener('beforeinstallprompt', listener);
//         }
//     })
//
//     return <div className={'add-to-home ' + (visible ? 'visible' : '')}>
//         {/*<header>افزودن به صفحه اصلی</header>*/}
//         <footer>
//             <button className={'btn add'}
//                     type={'button'}
//                     onClick={() => {
//                         installPromptEvent.prompt();
//                         console.log(installPromptEvent)
//                     }}>
//                 افزودن به صفحه‌اصلی
//             </button>
//             <button className="btn close" onClick={() => setVisible(false)}>
//                 بستن
//             </button>
//         </footer>
//     </div>;
// }
