import React from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
import ListView from "../../../components/ListView";
import CheckCard from "../../../components/CheckCard";

export default () => {
    return (<div className={'screen checks'}>
        <HeaderCurve title='لیست چک ها' back={true}/>
        <ListView uri={'/check'} Template={(post) => <CheckCard key={post.id} item={post}/>}/>
    </div>);
}