import {Component} from 'react';
import {inject} from "mobx-react";

@inject('store')
class Logout extends Component {
    constructor(props) {
        super(props);
        this.props.store.logout();
        props.history.replace('/login');
    }

    render() {
        return null;
    }
}

export default Logout;
