import React, {Component} from 'react';
import AuthCheck from "../../core/AuthCheck";
import appState from "../../store/appState";

export default class Splash extends Component {
    componentDidMount() {
        AuthCheck(true).finally(() => {
            setTimeout(() => {
                if (appState.logged_in) {
                    this.props.history.replace('/dashboard');
                } else {
                    this.props.history.replace('/login');
                }
            }, 500)
        });
    }

    render() {
        return (
            <div className={'screen splash'}>
                <div className="process">
                    <div></div>
                </div>
            </div>
        );
    }
}
