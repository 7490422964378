import React, {Component} from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
import {icons} from "../../../assets/images";
import api from "../../../api";
import {CSSTransition} from "react-transition-group";
import {observable} from "mobx";
import {observer} from "mobx-react";
import ListView from "../../../components/ListView";
import axios from "axios";
import {Link} from "react-router-dom";

const duration = 300;

const store = observable({
    categoriesOpen: false,
    categoryName: '',
    category: false,
    terms: [],
});

@observer
class Catalog extends Component {
    signal = axios.CancelToken.source();
    state = {
        error: '',
    };
    uri = '/post';
    mount = false;

    componentDidMount() {
        this.fetchTerms();
        this.mount = true;
    }

    componentWillUnmount() {
        this.mount = false;
        this.signal.cancel('Cancel: Catalog');
        this.render();
    }

    fetchTerms() {
        api().get('term', {
            cancelToken: this.signal.token,
        }).then(({data}) => {
            store.terms = data;
        });
    }

    openFilter = () => {
        store.categoriesOpen = true;
    };

    render() {
        let headers = {
            type: 'catalog',
        };
        if (store.category) {
            this.uri = '/term/posts';
            headers['term-id'] = store.category;
        } else if (this.mount) {
            this.uri = '/post';
        }
        return (
            <div className={'screen catalog'}>
                <HeaderCurve title={`کاتالوگ ${store.category ? ' - ' + store.categoryName : ''}`}/>
                <button className={'btn-filter'} onClick={this.openFilter}><img src={icons.filter} alt=""/></button>
                <ListView
                    key={`term-${store.category}`}
                    ref='listView'
                    className={'posts'}
                    uri={this.uri}
                    headers={headers}
                    Template={(post) => <Post key={post.id} post={post}/>}/>
                <Categories/>

            </div>
        );
    }
}


const changeCategory = (v) => {
    store.category = v;
    store.categoryName = v ? (store.terms.find(t => t.id === v) || {}).title : '';
    store.categoriesOpen = false;

};
const Categories = observer(() => {
    return (
        <>
            <Overlay visible={store.categoriesOpen}/>
            <div className={`catalog-categories ${store.categoriesOpen ? 'active' : ''}`}>
                <header>دسته بندی محصولات</header>
                <ul>

                    <li onClick={() => changeCategory(false)}>همه</li>
                    {store.terms.map((term) => <li key={term.id}
                                                   onClick={() => changeCategory(term.id)}>{term.title}</li>)}
                </ul>
            </div>
        </>
    );
});

function Overlay({visible}) {
    return (
        <CSSTransition
            timeout={duration}
            classNames="fade"
            in={visible}
            unmountOnExit
        >
            <div className={'overlay'} onClick={() => store.categoriesOpen = false}/>
        </CSSTransition>
    )
}

function Post({post}) {
    return (
        <article>
            <Link to={`/single/${post.id}`}>
                <div className="inner">
                    <img src={post.thumbnail} alt=""/>
                    <div className="title">{post.title}</div>
                </div>
            </Link>
        </article>
    );
}

export default Catalog;
