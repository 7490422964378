import React, {Component} from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
import api from "../../../api";
import {icons} from "../../../assets/images";
import classNames from "classnames";
import ResultMessage from "../../../components/ResultMessage";

export default class Suggestions extends Component {
    state = {
        loading: false,
        value: '',
        title: '',
        message: '',
    }
    submitForm = (e) => {
        e.preventDefault();
        if (!this.state.value) return;
        this.setState({
            loading: true,
            message: '',
        });
        api().post('feedback', {
            title: this.state.title || 'انتقاد و پیشنهاد',
            message: this.state.value,
        }).then(({data}) => {
            this.setState({
                message: data.message,
                value: '',
                title: '',
            })
        }).finally(() => {
            this.setState({
                loading: false
            });
        })
    }

    render() {
        return (
            <div className={'screen suggestions'}>
                <HeaderCurve title='فرم نظرسنجی' back={true}/>
                <div className="wrapper">
                    <form action="" onSubmit={(e) => this.submitForm(e)}>
                        <div>
                            <header className="label"><img src={icons.file} alt=""/> عنوان</header>
                            <input type="text" className={'form-control'}
                                   onChange={(e) => this.setState({title: e.target.value})}
                                   value={this.state.title} required
                            />
                        </div>
                        <div className={'mt-2'}>
                            <header className="label"><img src={icons.file} alt=""/> توضیحات</header>
                            <textarea name="" id="" className={'form-control'}
                                      onChange={(e) => this.setState({value: e.target.value})}
                                      value={this.state.value} required/>
                        </div>
                        <button type='submit'
                                className={classNames(['btn blue rounded', this.state.loading ? 'loading' : ''])}>ارسال
                            پیام
                        </button>
                        <ResultMessage message={this.state.message}/>
                    </form>
                </div>
            </div>
        );
    }
}
