import React from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
import ListView from "../../../components/ListView";
import {icons} from "../../../assets/images";
import {Link} from 'react-router-dom';

export default () => {
    return <div className={'screen blog'}>
        <HeaderCurve title='آموزش' back={true}/>
        {<ListView uri={'/post'}
                   headers={{
                       type: 'blog',
                   }}
                   Template={(post) => <Post key={post.id} post={post}/>} className={'posts'}/>}
    </div>
}

function Post({post}) {
    return (
        <article className={'blog-post'}>
            <header>
                <div className="image">
                    <img src={icons.aboutLogo} alt=""/>
                </div>
                <div>
                    <b>توزیع گستران</b>
                    {/*<div className={'date'}>8 دقیقه قبل</div>*/}
                </div>
            </header>
            <Link to={`/single/${post.id}`}>
                <div className="post-body">
                    <div className="poster">
                        <img src={post.thumbnail} alt=""/>
                    </div>
                    <h3>{post.title}</h3>
                    <div className="content">
                        {post.description}
                    </div>
                </div>
            </Link>
        </article>
    );
}
