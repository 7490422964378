import React, {Component} from 'react';
import classnames from "classnames";

class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
            touched: false
        }
    }

    touched = (touched) => {
        setTimeout(() => {
            this.setState({
                touched
            })
        }, 0);
    };

    handleClick = async () => {
        const {btn} = this.props;
        // if (btn.type === 'backspace') return;

        // digits
        this.props.addValue(btn.type);
        if (this.state.touched) {
            await this.touched(false);
        }
        await this.touched(true);
        setTimeout(() => {
            this.touched(false);
        }, 150);
    };

    render() {
        const {btn} = this.props;

        return (
            <button value={btn.type}
                    className={classnames({
                        done: btn.type === 'done',
                        loading: this.props.loading,
                        backspace: btn.type === 'backspace',
                        touched: this.state.touched,
                    })}
                    onClick={this.handleClick}
            >
                {btn.text}
            </button>
        );
    }
}


export default class Keyboard extends Component {
    constructor(props) {
        super(props);
        this.buttons = [];
        for (let i = 1; i <= 9; i++) {
            this.buttons.push({type: i, text: i})
        }
        this.buttons.push(
            ...['backspace', 0, 'done'].map((value) => ({
                type: value,
                text: typeof value === 'number' ? value : '',
                touched: false,
            }))
        );
    }

    render() {

        return (
            <div className={`keyboard ${this.props.loading ? ' loading' : ''}`}>
                {this.buttons.map((btn, key) => <Button
                    key={key} btn={btn}
                    loading={btn.type === 'done' && this.props.loading}
                    addValue={this.props.addValue}
                />)}
            </div>
        );
    }

}