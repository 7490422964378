import axios from "axios";
import appState from "./store/appState";

// axios.defaults.headers.common['token'] = appState.token;
/**
 * @return AxiosInstance
 */
export default () => axios.create({
    baseURL: 'https://tgostaran.liara.run/api/v1/app/',
    headers: {
        token: appState.token
    }
});
export const apiAuth = () => axios.create({
    baseURL: 'https://tgostaran.liara.run/api/v1/auth/',
});

//eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJsdW1lbi1qd3QiLCJzdWIiOjEsImlhdCI6MTU2NTI5MjU4NiwiZXhwIjoxNTk2Mzk2NTg2fQ.8mx4K6rh845ZnwzKOvGRHrMBWihHyD-32wtmiBh2uyY
