import React from 'react';
import {Route, withRouter} from "react-router-dom";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import styled from "styled-components";
import Routes from "./views/Routes";
import Logout from "./views/Screens/Logout";

const duration = 500;

function onEnter() {
    document.querySelector('#root').classList.add('navigating')
}

function onExit() {
    document.querySelector('#root').classList.remove('navigating')
}

function Container({location}) {

    return (
        <Wrapper>
            <Route path="/logout" component={Logout}/>
            <TransitionGroup className="transition-group">
                <CSSTransition
                    key={location.key}
                    timeout={duration}
                    onEntering={onEnter}
                    onExited={onExit}
                    classNames="fade"
                >
                    <section className="route-section">
                        <Routes location={location}/>
                    </section>
                </CSSTransition>
            </TransitionGroup>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  height: 100%;
  div.transition-group {
       position: relative;    
       height: 100%;   
  }
  section.route-section {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;


export default withRouter(Container);

