import React from 'react';
import {rgb} from "../core/Colors";
import Card from "./Card";
import {priceFormat} from "../core/helpers";

export default (props) => {
    let {item} = props;
    return (
        <Card color={rgb.red}>
            <div className="d-flex flex-space-between">
                <div><b>{item.bank_branch_name}</b></div>
                <div><b>{priceFormat(item.amount)}</b> ریال</div>
            </div>
            <div className="d-flex flex-space-between">
                <div>شماره چک {item.check_number}</div>
                <div>تاریخ سر رسید: <b>{item.date_of_payment_at}</b></div>
            </div>
        </Card>
    );
}
