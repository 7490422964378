import React from 'react';
import {icons} from "../assets/images";
import {withRouter} from "react-router-dom";

function ButtonBack(props) {
    const back = () => {
        // if (props.history.length > 1) {
        //     props.history.goBack();
        // } else {
        // }
        props.history.push('/dashboard');
    };

    return <button className={'go-back'} onClick={back}><img src={icons.back} alt=""/></button>;
}

export default withRouter(ButtonBack);
