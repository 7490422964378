import React, {useEffect} from 'react';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import {getMeta, GoogleMapApiKey} from "../../../core/helpers";
import ButtonBack from "../../../components/ButtonBack";

const MapComp = () => {
    useEffect(() => {
        document.body.classList.add('dark-statusbar')
        return () => {
            document.body.classList.remove('dark-statusbar')
        };
    }, []);

    return (
        <div className={'screen map'}>
            <ButtonBack/>
            <MapView
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapApiKey}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{height: `100%`}}/>}
                containerElement={<div style={{height: `${window.innerHeight - 80}px`}}/>}
                mapElement={<div style={{height: `100%`}}/>}
            >
            </MapView>
            <div className="address">
                {getMeta('address')}
            </div>
        </div>
    );
}

const MapView = withScriptjs(withGoogleMap(() => {
    const defaultMapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
    };
    
    let [lat, lng] = [
        getMeta('lat') || 31.272838592529297,
        getMeta('lng') || 48.78725814819336
    ];
    console.log(lat,lng);
    

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{lat, lng}}
            defaultOptions={defaultMapOptions}
        >
            <Marker position={{lat, lng}}/>
        </GoogleMap>
    );
}))

export default MapComp;
