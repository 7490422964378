import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {Redirect} from "react-router-dom";
import Sidebar from "../Screens/Dashboard/Sidebar";
import AuthCheck from "../../core/AuthCheck";
import Loading from "../../components/Loading";
import HeaderCurve from "../../components/HeaderCurve";
import ModalHelp from "../Screens/Dashboard/ModalHelp";
import ModalUploader from "../Screens/Dashboard/ModalUploader";
import appState from "../../store/appState";

// @withRouter
@observer
@inject('store')
class DashboardContainer extends Component {
    state = {
        loading: true,
    };

    constructor(props) {
        super(props);
        AuthCheck().finally(() => {
            this.setState({
                loading: false
            })
        });
        appState.notify();
    }

    sidebarClick = () => {
        this.props.store.isSidebar = true;
    };

    render() {
        const {store} = this.props;
        let Content;

        if (this.state.loading) {
            Content = <>
                <HeaderCurve title='داشبورد'/>
                <div className="wrapper list-view"><Loading/></div>
            </>;
        } else {
            if (this.props.store.logged_in) {
                Content = <>
                    <Button onClick={this.sidebarClick}/>
                    <Sidebar store={store}/>
                    <ModalHelp/>
                    <ModalUploader/>
                    {this.props.children}
                </>;
            } else {
                Content = <Redirect to='/login'/>;
            }
        }

        return (
            <div className={'screen dashboard'}>
                {Content}
            </div>
        );
    }
}

class Button extends Component {
    render() {
        return (
            <div className={'hamburger-menu-btn'} onClick={this.props.onClick}>
                <span/> <span/> <span/>
            </div>
        );
    }
}

export default DashboardContainer;
