import React, {Component} from 'react';
import HeaderNormal from "../../../components/HeaderNormal";
import {icons} from '../../../assets/images';

import classnames from "classnames";
import FactorCard from "../../../components/FactorCard";
import GiftCard from "../../../components/GiftCard";
import appState from "../../../store/appState";
import {observer} from "mobx-react";
import {getMeta} from "../../../core/helpers";

@observer
class Dashboard extends Component {
    notify_id = 0;

    render() {
        const {user, dashboard} = appState;
        const store = {
            image: appState.user.img && appState.user.img !== 'none' ? appState.user.img : icons.store,
            name: 'فروشگاه مواد غذایی',
            admin: user.name,
            phone: user.phone,
            date_of_birth: getMeta('date_of_birth'),
            meet_day: getMeta('meet_day'),
            discounts: `${dashboard.allGiftAmount} ریال`,
            discounts_ready: `${dashboard.allLastGiftAmount} ریال`,
            location: getMeta('address'),
        };

        let {lastFactor, lastActiveGift} = dashboard;

        return (
            <div className={'screen home'}>
                <HeaderNormal>
                    <div className="top">
                        <div className="image"><img src={store.image} alt=""/></div>
                        <div className="title">{store.name}</div>
                    </div>

                    <div className="bottom details">
                        <DetailItem image={icons.user} label='' value={store.admin}/>
                        <DetailItem image={icons.discounts} label='مجموع تخفیف ها' value={store.discounts}/>
                        <DetailItem image={icons.phone} label='شماره تلفن' value={store.phone}/>
                        <DetailItem image={icons.tag} label='مجموع تخفیف موجود' value={store.discounts_ready}/>
                        <DetailItem image={icons.date} label='تاریخ تولد' value={store.date_of_birth}/>
                        <DetailItem image={icons.date} label='روز ویزیت' value={store.meet_day}/>
                        <DetailItem image={icons.location} label='آدرس' value={store.location}/>
                    </div>
                </HeaderNormal>
                <div className="wrapper">
                    {/*<button onClick={this.subscribe}>Notify!</button>*/}

                        {lastFactor && <FactorCard
                            title={`پیش فاکتور: ${lastFactor.Pre_factor_number}`}
                            link='factors'
                            factor={lastFactor}/>}

                    <div>
                        {lastActiveGift && lastActiveGift.map((gift, i) => (
                            <GiftCard title={i === 0 ? 'کارت هدیه' : ''}
                                      link={i === 0 ? 'gift-card' : ''}
                                      key={gift.id}
                                      gift={gift}/>
                        ))}
                    </div>

                    <ImageCard user={{
                        name: getMeta('visitor_name'),
                        img: getMeta('visitor_img'),
                    }}/>
                    <ImageCard user={{
                        name: getMeta('supervisor_name'),
                        img: getMeta('supervisor_img'),
                    }} className={'mt-2'} title={'سوپروایزر توزیع گستران'}/>
                </div>
            </div>
        );
    }
}

function DetailItem({image, label, value}) {
    return (
        <div className={'item'}>
            <img src={image} alt=""/>
            <div>
                <span className="label">{label && (label + ':')}</span> <span className="value">{value}</span>
            </div>
        </div>
    );
}

function ImageCard({user, className = '', title = 'بازاریاب توزیع گستران'}) {
    // if (!user.name || user.name === 'null') return <></>;
    return (
        <div className={classnames(['image-card', className])}>
            <div className="image"><img src={user.img && user.img !== 'none' ? user.img : icons.store} alt=""/></div>
            <header>{title}</header>
            <div className="details">
                <div>
                    <span className="icon">
                        <img src={icons.user_filled} alt=""/>
                    </span>
                    <span className="label">{user.name}</span>
                    {/*<span className="label">نام سوپروایزر:</span>*/}
                    {/*<span className="text">{user.name}</span>*/}
                </div>
                {/*<div>*/}
                {/*    <span className="icon"><img src={icons.phone_filled} alt=""/></span>*/}
                {/*    <span className="label">شماره تلفن:</span>*/}
                {/*    <span className="text">{user.phone}</span>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <span className="icon"><img src={icons.email} alt=""/></span>*/}
                {/*    <span className="label">ایمیل:</span>*/}
                {/*    <span className="text">{user.email}</span>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Dashboard;
