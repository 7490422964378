import React from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
import ListView from "../../../components/ListView";
import FactorCard from "../../../components/FactorCard";

export default () => {
    return (<div className={'screen checks'}>
        <HeaderCurve title='لیست پیش فاکتور' back={true}/>
        <ListView uri={'/factor'} Template={(post) => <FactorCard key={post.id} factor={post}/>}/>
    </div>);
}