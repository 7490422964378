import store from "../store/appState";
import api from "../api";

export default async (force = false) => {
    if (!force && store.logged_in) return true;

    if (window.apiToken) {
        localStorage.setItem('token', window.apiToken);
    }
    store.token = localStorage.getItem('token') || window.apiToken;
    return api().get('/dashboard').then(({data}) => {
        let {user, ...dashboard} = data;
        store.user = user;
        store.dashboard = dashboard;
        store.logged_in = true;
    }).catch(() => {
        store.logged_in = false;
    });
}
