import React, {Component} from 'react';
import {CSSTransition} from "react-transition-group";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {icons } from "../../../assets/images";
import appState from "../../../store/appState";
import api from "../../../api";
import AuthCheck from "../../../core/AuthCheck";

const duration = 300;
const store = observable({
    isModalOpen: false,
    uploading: false
});

export const modal = {
    open() {
        document.body.classList.add('modal-open')
        store.isModalOpen = true;
    },
    close() {
        document.body.classList.remove('modal-open')
        store.isModalOpen = false

    }
}

@observer
class ModalUploader extends Component {

    componentDidMount() {
        // modal.open();
    }

    render() {
        return (
            <CSSTransition
                timeout={duration} classNames="fade"
                unmountOnExit
                in={store.isModalOpen}
            >
                <div className={'modal-box modal-uploader transition-group'}>
                    <Overlay/>
                    <Modal/>
                </div>
            </CSSTransition>
        );
    }
}

function uploadImage(e) {
    if (!e.target.files.length)
        return;
    const data = new FormData();
    data.append('img', e.target.files[0]);
    data.append('_method', 'PUT');
    store.uploading = true;

    api().post('dashboard', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(() => {
            AuthCheck(true);
        })
        .finally((data) => {
            store.uploading = false;
            modal.close()
        })
}

const Modal = observer(() => {
        return <div className={'modal-wrapper'}>
            <div className="close-btn" onClick={modal.close}/>
            <img src={appState.user.img && appState.user.img !== 'none' ? appState.user.img : icons.store} alt=""/>
            <header>آپلود تصویر پروفایل</header>
            <div className="content">
                تغییر تصویر پروفایل
            </div>
            <button className={'btn blue ' + (store.uploading ? 'loading' : '')}>
                <label htmlFor="image">انتخاب فایل</label>
                <input type="file" id='image' onChange={uploadImage} accept={'image/*'}/>
            </button>
        </div>;
    }
)

function Overlay() {
    return <div className={'modal-overlay overlay'} onClick={modal.close}/>;
}

export default ModalUploader;
