import React from 'react';
import {rgb} from "../core/Colors";
import {priceFormat} from "../core/helpers";
import Card from "./Card";

export default (props) => {
    let {factor} = props;
    return (
        <Card {...props} color={rgb.blue}>
            <div className={'row'}>
                {factor.status && <div className={'col color-blue '}>
                    {factor.status}
                </div>}
                {!factor.status && <div className="col">
                    شماره پیش فاکتور: {factor.Pre_factor_number}
                </div>}
            </div>
            <div className="row">
                <div className={'col-6'}>
                    مبلغ فاکتور: <b>{priceFormat(factor.pure_amount)} ریال</b>
                </div>
                <div className={'col-6 text-left'}>
                    مبلغ تخفیف: <b>{priceFormat(factor.gift_amount)} ریال</b>
                </div>
            </div>
            <div className="-row">
                    مبلغ قابل پرداخت: <b>{priceFormat(factor.payable_amount)} ریال</b>
            </div>
            <div className="row">
                <div className="col">
                    <span>نام مامور پخش:</span>
                    <b>{factor.broadcast_agent}</b>
                </div>
            </div>
        </Card>
    );
}
