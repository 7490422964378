// @ts-nocheck
import React from 'react';
import HeaderCurve from "../../../components/HeaderCurve";

export default () => {
    return (
        <div className={'screen certificates'}>
            <HeaderCurve title='مجوز های توزیع' back={true}/>
            <div className="wrapper list-view posts">
                <article>
                    <div className={'inner'}><img src={require('../../../assets/images/c1.jpg')} alt=""/></div>
                </article>
                <article>
                    <div className={'inner'}><img src={require('../../../assets/images/c2.jpg')} alt=""/></div>
                </article>
                <article>
                    <div className={'inner'}><img src={require('../../../assets/images/c3.jpg')} alt=""/></div>
                </article>
                <article>
                    <div className={'inner'}><img src={require('../../../assets/images/c4.jpg')} alt=""/></div>
                </article>
                <article>
                    <div className={'inner'}><img src={require('../../../assets/images/c5.jpg')} alt=""/></div>
                </article>
                <article>
                    <div className={'inner'}><img src={require('../../../assets/images/c6.jpg')} alt=""/></div>
                </article>
                
            </div>
        </div>
    );
}
