import React, {Component} from 'react';
import ButtonBack from "../../../components/ButtonBack";
import {icons} from "../../../assets/images";

export default class AboutUs extends Component {
    render() {
        return (
            <div className={'screen about-us'}>
                <ButtonBack/>
                <header className="main">
                    <header className="title">
                        <div className="logo"><img src={icons.aboutLogo} alt=""/></div>
                        <span>درباره توزیع گستران</span>
                    </header>
                </header>
                <div className="wrapper">
                    {/* <div className="d-flex flex-center-main">
                        <img src={icons.aboutLogo} alt="" className="logo"/>
                    </div> */}
                    <div className="content">
                        شرکت توزیع گستران فعالیت خودر را از سال 1382 در زمینه توزیع مواد غذایی و بهداشتی آغاز نموده و با
                        توجه به تغییرات بوجود آمده در شرایط اقتصادی و اجتماعی جامعه ، پیدایش رقبای جدید و توانمند و
                        همچنین در راستای رشد و تعالی خود سعی نموده روز به روز در مسیر تکامل قدم برداشته و با بکارگیری
                        روش ها و تکنینک های مختلف در مسیر موفقیت قدم بردارد.
                        <br/>
                        ما حلقه ای بزرگ از زنجیره تولید تا مصرف محصولات غذایی و بهداشتی کشور هستیم که واسطه های
                        پرتعداد میان تولیدکنندگان و مشتریان را حذف کرده و بلا واسطه با سیستم جامع و منظم پخش مویرگی
                        محصولات را به دست مشتریان ) سوپرمارکت داران ، رستوران ها و سایر اصناف ) می رسانیم . رسالت ما
                        رسانیدن مرغوبترین محصولات گروه صنایع غذایی و بهداشتی کشور ، درکوتاه ترین زمان و بلاواسطه با
                        بهره گیری از سیستم علمی پخش مویرگی حتی تا دورترین نقاط استان پهناور خوزستان، به دست مشتریان
                        است . امید آنکه با عنایت به رضایت مشتریان در این مسیر استوار و ثابت قدم باشیم .
                        <br/>
                        در راستای افزایش رضایت مشتریان و ذینفعان و همچینین بکارگیری سیستم مدیریت یکپارچه بر اساس
                        استانداردهای بین المللی اقدام به اخذ گواهی نامه های (ISO 9001:2015 , ISO 10002:2018 ,
                        ISO 10004:2018) در دامنه تامین،انبارش و توزیع مواد غذایی و بهداشتی نموده و به این موفقیت
                        نایل آمده ایم.

                    </div>
                </div>
            </div>
        );
    }
}
