import React from 'react';
import {rgb} from "../core/Colors";
import {priceFormat} from "../core/helpers";
import Card from "./Card";

const color = {
    active: rgb.green,
    expire: rgb.red,
    used: rgb.blue,
}


export default (props) => {
    let {gift} = props;

    return (
        <Card  {...props} color={color[gift.status || 'active']} className={'single-line gift'}>
            <div className="">{gift.title}</div>
            <div style={{color: `rgb(${color[gift.status || 'active'].join(', ')})`}}><b>{priceFormat(gift.amount)} </b> ریال</div>
            <div className="">{gift.expire_at}</div>
        </Card>
    );
}
