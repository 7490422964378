import React from 'react';

const ResultMessage = ({message}) => {
    if(!message)
        return <div/>
    return (
        <div className={'message-success'}>
            {message}
        </div>
    );
};

export default ResultMessage;
