export const icons = {
    store: require('./store.png'),
    back: require('./back.svg'),
    user: require('./user.svg'),
    discounts: require('./discounts.svg'),
    tag: require('./tag.svg'),
    phone: require('./phone.svg'),
    location: require('./Explore.svg'),
    arrowLeft: require('./arrow.svg'),
    user_filled: require('./user-filled.svg'),
    phone_filled: require('./phone-filled.svg'),
    email: require('./mail.svg'),
    aboutLogo: require('./aboutus-logo.png'),
    aboutImage: require('./about-us.jpg'),
    file: require('./file.svg'),
    instagram: require('./instagram.svg'),
    mapRed: require('./map-red.png'),
    phoneRed: require('./phone-red.png'),
    filter: require('./filter.svg'),
    modal: require('./chat.svg'),
    camera: require('./camera.svg'),
    star_stroke: require('./star-regular.svg'),
    star_fill: require('./star-solid.svg'),
    date: require('./calendar-alt-regular.svg')
};

export const sidebar = {
    email: require('./red-email.svg'),
    save: require('./red-save.svg'),
    home: require('./red-home.svg'),
    info: require('./red-info.svg'),
    add: require('./red-add.svg'),
    list: require('./red-list.svg'),
    lock: require('./red-lock.svg'),
    building: require('./red-building.svg'),
    phone: require('./red-phone.svg'),
};
