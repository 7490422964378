import React, {Component} from 'react';
import HeaderCurve from "../../../components/HeaderCurve";
// import QrCode from "./QRCode";
import {inject, observer} from "mobx-react";
import {Redirect} from "react-router-dom";
import {apiAuth} from "../../../api";
import {Transition} from "react-transition-group";

const duration = 300;
const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    transform: `scale(.5)`
};
const transitionStyles = {
    entering: {opacity: 1, transform: `scale(1)`},
    entered: {opacity: 1, transform: `scale(1)`},
    exiting: {opacity: 0, transform: `scale(.5)`},
    exited: {opacity: 0, transform: `scale(.5)`},
};

@inject('store')
@observer
class Login extends Component {
    state = {
        error_msg: ''
    };

    componentDidMount() {
        console.log('login-page');
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let {uuid, phone} = this.props.store.user;

        this.setState({
            error_msg: '',
            loading: true
        });
        apiAuth()
            .post('login', {phone, uuid})
            .then(({data}) => {
                if (data.success) {
                    this.props.history.replace('/verify')
                } else {
                    this.setState({
                        error_msg: data.message
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    };

    render() {
        if (this.props.store.logged_in) {
            return <Redirect to='/dashboard'/>;
        }
        return (
            <div className={'screen login'}>
                <form action="/" onSubmit={this.handleSubmit}>
                    <HeaderCurve title={'ورود'} listeners={false}>
                        <div className="form phone-number">
                            <div className="label">شماره موبایل</div>
                            <input type="number" className={'form-control'} autoFocus required
                                   placeholder={'0912*******'}
                                   value={this.props.store.user.phone}
                                   onChange={({target: {value}}) => this.props.store.user.phone = value}/>
                        </div>
                    </HeaderCurve>

                    <div className="wrapper center">
                        {/*<QrCode/>*/}
                        <button type='submit' className={`btn red mt-5 ${this.state.loading ? "loading" : ""}`}>
                            ورود
                        </button>
                        <Transition in={!!this.state.error_msg} timeout={duration}>
                            {state => (
                                <div className='center' style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state]
                                }}>
                                    <p className={'error color-red'}>{this.state.error_msg}</p>
                                </div>
                            )}
                        </Transition>
                    </div>
                </form>
            </div>
        );
    }
}

export default Login;
