import React, {Component} from 'react';
import axios from 'axios'
import classnames from 'classnames';

import Loading from "./Loading";
import api from "../api";
import {getScrollTop} from "../core/helpers";

export default class ListView extends Component {
    signal = axios.CancelToken.source();
    mount = false;
    state = {
        loading: true,
        posts: [],
        error: '',
        current_page: 1,
        last_page: 1,
    };

    componentDidMount() {
        this.mount = true;

        this.loadData();
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        this.signal.cancel();
        this.mount = false;
        window.removeEventListener('scroll', this.onScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.uri !== prevProps.uri || this.props.headers !== prevProps.headers) {
            setTimeout(() => {
                if (this.mount) {
                    this.setState({
                        posts: []
                    })
                    this.loadData();
                }
            }, 300)
        }
    }

    loadData = () => {
        let {uri} = this.props;
        this.setState({
            loading: true
        })

        let headers = this.props.headers ? {...this.props.headers} : {};
        console.log(`CurrentPage:${this.state.current_page}`)
        api()
            .get(uri, {
                cancelToken: this.signal.token,
                params: {
                    page: this.state.current_page
                },
                headers
            })
            .then(({data}) => {
                this.setState({
                    posts: [...this.state.posts, ...data.data],
                    last_page: data.last_page,
                    current_page: data.current_page,
                });
            })
            .finally(() => {
                if (this.mount) {
                    this.setState({
                        loading: false
                    })
                }

            })
    }
    loadAdditionalData = () => {
        if (this.state.current_page < this.state.last_page) {
            this.setState(({current_page}) => ({
                current_page: +current_page + 1
            }));

            this.loadData();
        }
    }

    onScroll = () => {
        if (this.state.loading)
            return;
        let t = getScrollTop();
        let {wrapper} = this.refs;
        let data = wrapper.getBoundingClientRect();
        let bottomPos = Math.max(data.height - window.innerHeight - 30, 500);
        if (t > bottomPos) {
            this.loadAdditionalData()
        }
    }

    render() {
        let {Template, className} = this.props;
        return (
            <div className={classnames(['wrapper list-view', className])} ref={'wrapper'}>
                {this.state.posts.map((post, index) => Template(post, index))}
                {!this.state.loading && this.state.posts.length === 0 && <div className="hint-box">
                    اطلاعاتی یافت نشد.
                </div>}
                {this.state.loading && <Loading/>}
            </div>
        );
    }
}
